import { useStyles } from "hooks/useStyles";

import ListPageStyles from "styles/ListPageStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  actionButton: {
    ...ListPageStyles.actionButton,

    [ScreenSizes.lgAndAbove]: {
      marginBottom: 0,
    },
  },
};

function ListHeaderButton(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return <div className={css(styles.actionButton)}>{props.children}</div>;
}

export default ListHeaderButton;

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  contentHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",

    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
    },

    [ScreenSizes.xlAndAbove]: {
      flexDirection: "row",
    },
  },
  withPadding: {
    padding: "1rem 1rem 1rem 1rem",

    [ScreenSizes.mdAndAbove]: {
      padding: "1rem 2rem 1rem 2rem",
    },
  },
  noPadding: {
    padding: 0,

    [ScreenSizes.mdAndAbove]: {
      padding: 0,
    },
  },
};

function ListHeaderWrapper(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div
      className={css(
        styles.contentHeader,
        props.noPadding ? styles.noPadding : styles.withPadding
      )}
    >
      {props.children}
    </div>
  );
}

export default ListHeaderWrapper;

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
    flexWrap: "wrap",
    width: "100%",
    height: "max-content",
    gap: "1rem",

    [ScreenSizes.mdAndAbove]: {
      marginBottom: 0,
    },

    [ScreenSizes.lgAndAbove]: {
      width: "100%",

      gap: "1rem",
      marginBottom: 0,
      flexDirection: "column",

      justifyContent: "flex-start",
    },

    [ScreenSizes.xlAndAbove]: {
      flexWrap: "no-wrap",

      flexDirection: "row",
      gap: ".5rem",
    },
  },
};

function ListHeaderButtons(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return <div className={css(styles.actionButtons)}>{props.children}</div>;
}

export default ListHeaderButtons;
